/* eslint-disable indent */
import React, { useState, useEffect, useMemo } from 'react';
import { Box, Button, CircularProgress, useTheme } from '@mui/material';
import Tab from '@mui/material/Tab';
import { TabContext, TabPanel, TabList } from '@mui/lab';
import { useParams } from 'react-router';
import EvaluationAnalysis from './EvaluationAnalysisScore';
import EvaluationAnalysisNotes from './EvaluationAnalysisScoreNotes';
import useGetEvaluationQuestionCategories from '../../hooks/useGetEvaluationQuestionCategory';
import { useAppSelector } from '../../../../redux/store';
import { EvaluationQuestionCategories, EvaluationResultAnalysisResponse } from '../../type';
import BarChartIcon from '@mui/icons-material/BarChart';
import IndividualResponseModal from '../EvaluationAnalysisContainer/IndividualResponseModal';
import useGetEvaluationCategoryAnalysisResults from '../../hooks/useGetEvaluationCategoryAnalysisResults';

const EvaluationScoreAnalysisContainer = () => {
  const { id } = useParams();
  const evaluationQuestionCategoryReducer = useGetEvaluationQuestionCategories();
  const theme = useTheme();

  const { getEvaluationCategoriesAnalysisResults } = useGetEvaluationCategoryAnalysisResults();

  const evaluationQuestionCategories = useAppSelector((state) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return state.allEvaluationReducers.getEvaluationQuestionCategoryReducer
      .evaluationQuestionCategories?.results;
  });

  const evaluationCategoryAnalysisResponses: { [categoryId: string]: EvaluationResultAnalysisResponse } =
    useAppSelector((state) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return state.allEvaluationReducers.getEvaluationCategoryAnalysisResultsResponseReducer
        .evaluationResultResponses;
    });

  const filterCategoriesWithQuestions = (
    categories: EvaluationQuestionCategories[],
    responses: { [key: string]: EvaluationResultAnalysisResponse }
  ): EvaluationQuestionCategories[] => {
    return categories.filter(category => {
      const response = responses[category.id];
      return response && response.number_of_questions > 0;
    });
  };

  const filteredCategories = useMemo(() => {
    if (evaluationQuestionCategories) {
      return filterCategoriesWithQuestions(evaluationQuestionCategories, evaluationCategoryAnalysisResponses);
    }
    return [];
  }, [evaluationQuestionCategories, evaluationCategoryAnalysisResponses]);

  const [value, setValue] = useState<string>('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState<string>('');
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (filteredCategories.length > 0 && !value) {
      setValue(filteredCategories[0].id);
    }
  }, [filteredCategories, value]);

  useEffect(() => {
    const foundCategory = evaluationQuestionCategories?.find((category) => category.id === value);
    setSelectedCategory(foundCategory ? foundCategory.category : '');
  }, [value, evaluationQuestionCategories]);

  useEffect(() => {
    if (evaluationQuestionCategories && evaluationQuestionCategories.length > 0) {
      const surveyData = evaluationQuestionCategories.map(category => ({
        evaluation_id: id!,
        question_category_id: category.id,
      }));

      getEvaluationCategoriesAnalysisResults(surveyData);
    }
  }, [id, evaluationQuestionCategories]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    setCurrentIndex(0);
  };

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  useEffect(() => {
    evaluationQuestionCategoryReducer.getQuestionCategories();
  }, []);

  const isValidValue = filteredCategories.some(category => category.id === value) || value === 'notes';

  return (
    <div>
      {evaluationQuestionCategoryReducer.error ||
        evaluationQuestionCategoryReducer.loading ||
        filteredCategories.length === 0 ? (
        <CircularProgress sx={{ margin: '20vh 48%' }} />
      ) : (
        isValidValue && (
          <TabContext value={value}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <TabList
                sx={{
                  '& .MuiTab-root': {
                    '&.Mui-selected': {
                      color: '#00DB99',
                    },
                    textTransform: 'none',
                  },
                }}
                onChange={handleChange}
                TabIndicatorProps={{ style: { backgroundColor: '#00DB99' } }}
                aria-label="lab API tabs example"
              >
                {filteredCategories.map(
                  (category: EvaluationQuestionCategories, index) => (
                    <Tab
                      key={category.id}
                      label={category.category}
                      value={category.id}
                    />
                  ))}
                <Tab label="Evaluation Notes" value="notes" />
              </TabList>

              {value !== "notes" && (
                <Button
                  endIcon={<BarChartIcon />}
                  variant="contained"
                  sx={{
                    backgroundColor: theme.palette.primary.main,
                    '&:hover': { backgroundColor: theme.palette.primary.main, }
                  }}
                  onClick={handleOpenModal}
                >
                  Individual Responses
                </Button>
              )}
            </Box>

            {filteredCategories.map(
              (category: EvaluationQuestionCategories) => (
                <TabPanel
                  sx={{ padding: '0' }}
                  value={category.id}
                  key={category.id}
                >
                  <EvaluationAnalysis
                    id={id || ''}
                    evaluationQuestionCategoryId={category.id}
                  />
                </TabPanel>
              ),
            )}
            <TabPanel value="notes">
              <EvaluationAnalysisNotes
                id={id || ''}
              />
            </TabPanel>
          </TabContext>
        )
      )}

      <IndividualResponseModal
        open={modalOpen}
        handleClose={handleCloseModal}
        evaluationId={id || ''}
        questionCategoryId={value || ''}
        questionCategory={selectedCategory || ''}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
      />
    </div>
  );
};

export default EvaluationScoreAnalysisContainer;
