import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../types/request';
import axios, { AxiosError } from 'axios';
import axiosInstance from '../../../config/axios';
import { EvaluationImageUrlResponse } from '../type';

export interface EvaluationImageUrlReducer {
  evaluationImageDetails: EvaluationImageUrlResponse | null;
  status: RequestStatus;
}

const initialState: EvaluationImageUrlReducer = {
  evaluationImageDetails: null,
  status: RequestStatus.Init,
};

type EvaluationIdRequest = string;

export const getEvaluationImageUrl = createAsyncThunk<
  EvaluationImageUrlResponse, // Type of the return value (payload)
  EvaluationIdRequest, // Type of the argument
  {
    rejectValue: AxiosError // Type for rejectWithValue
  }
>(
  'evaluationImageUrl/getEvaluationImageUrl',
  async (shareableLinkId: string, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get<EvaluationImageUrlResponse>(
        `evaluation/${shareableLinkId}/imagedetails/`
      );
      return response.data as EvaluationImageUrlResponse; // Specify the type of response.data
    } catch (error) {
      if (axios.isAxiosError(error)) {
        // Ensure that the rejection is of the type AxiosError
        return rejectWithValue(error);
      }
      throw error; // Throw error if it's not an AxiosError
    }
  }
);

const getEvaluationImageUrlSlice = createSlice({
  name: 'getEvaluationImageUrl',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEvaluationImageUrl.pending, (state) => {
      state.status = RequestStatus.Loading;
    });
    builder.addCase(getEvaluationImageUrl.fulfilled, (state, action) => {
      state.status = RequestStatus.Done;
      state.evaluationImageDetails = action.payload;
    });
    builder.addCase(getEvaluationImageUrl.rejected, (state) => {
      state.status = RequestStatus.Error;
      state.evaluationImageDetails = null;
    });
  },
});

export default getEvaluationImageUrlSlice.reducer;
