import React, { ReactNode, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Provider, useSelector } from 'react-redux';
import { store } from './redux/store';
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';
import ResetPasswordComponent from './modules/Auth/ResetPassword/ResetPassword';
import EnquiryForm from './components/EnquiryForm/EnquiryForm';
import LegacyEnquiryForm from './components/EnquiryForm/LegacyEnquiryForm';
import AccountExecutiveDashboard from './modules/Dashboard/AccountExecutiveDashboard/AccountExecutiveDashboard';
import ClientDashboard from './modules/Dashboard/Client/ClientDashboard'
import ClientList from './modules/Accounts/ClientList/ClientList';
import EvaluationList from './modules/Evaluations/EvaluationList/EvaluationList';
import AccountDetailView from './modules/Accounts/AccountDetailView/AccountDetailView';
import EvaluationDetailView from './modules/Evaluations/EvaluationDetailView/EvaluationDetailView';
import { RootStoreType } from './redux/store.type';
import AppRouter from './AppRouter';
import CreateClient from './modules/Accounts/CreateClient/CreateClient';
import CreateEvaluation from './modules/Evaluations/CreateEvaluation/CreateEvaluation';
import Settings from './modules/Settings/Settings';
import { SuccessPage } from './components/EnquiryForm/SuccessPage';
import EvaluationScore from './modules/Evaluations/EvaluationScore/EvaluationScore';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoginComponent from './modules/Auth/Login/Login';
import PageNotFound from './modules/PageNotFound';
import EditEvaluation from './modules/Evaluations/EditEvaluation/EditEvaluation';
import EditAccount from './modules/Accounts/EditAccount/EditAccount';
import EvaluationScoreAnalysisContainer from './modules/Evaluations/EvaluationAnalysis/EvaluationAnalysisScore/EvaluationAnalysisScoreContainer';
import ForgetPasswordComponent from './modules/Auth/ForgetPassword/ForgetPassword';
import { BrandingProvider, useBranding } from './modules/Branding/reducers/getBranding';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { CustomAccountBranding } from './modules/Branding/type';
import LegacyLoginComponent from './modules/Auth/Login/LegacyLogin';
import Scoin from './modules/Scoin/Scoin';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const AppInitializer = () => {
  const branding = useBranding();

  useEffect(() => {
    const setFavicon = (faviconPath: string) => {
      let link: HTMLLinkElement | null = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      link.href = faviconPath;
    };

    const setAppleTouchIcon = (iconPath: string) => {
      let link: HTMLLinkElement | null = document.querySelector("link[rel~='apple-touch-icon']");
      if (!link) {
        link = document.createElement('link');
        link.rel = 'apple-touch-icon';
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      link.href = iconPath;
    };

    document.title = branding?.browser_tab_title || 'Smart Assessments';
    setFavicon(branding?.favicon || '../../assets/atomLogo192.png');
    setAppleTouchIcon(branding?.favicon || '../../assets/atomLogo192.png');
  }, [branding]);

  const theme = createTheme({
    palette: {
      primary: {
        main: branding?.primary_color || '#1e73ae',
      },
      secondary: {
        main: branding?.secondary_color || '#00314b',
      },
    },
  });

  const ProtectedRoute = ({ children }: { children: React.ReactElement }) => {
    const { currentUser } = useSelector(
      //eslint-disable-next-line @typescript-eslint/no-unsafe-return
      (state: RootStoreType) => state.authReducers.user,
    );
    if (!currentUser) {
      return <Navigate to="/" replace />;
    } else {
      return children;
    }
  };

  const router = createBrowserRouter([
    {
      path: '/',
      element: branding?.company_name.toLowerCase().includes('washington')
        ? (<LegacyLoginComponent />) as ReactNode
        : (<LoginComponent />) as ReactNode,
      errorElement: (<PageNotFound />) as ReactNode,
    },
    {
      path: '/forget-password',
      element: (<ForgetPasswordComponent />) as ReactNode,
      errorElement: (<PageNotFound />) as ReactNode,
    },
    {
      path: '/reset-password',
      element: (<ResetPasswordComponent />) as ReactNode,
      errorElement: (<PageNotFound />) as ReactNode,
    },
    {
      path: '/evaluate/:id',
      element: branding?.company_name.toLowerCase().includes('washington')
        ? (<LegacyEnquiryForm />) as ReactNode
        : (<EnquiryForm />) as ReactNode,
      errorElement: (<PageNotFound />) as ReactNode,
    },
    {
      path: '/success-evaluation-assessment',
      element: (<SuccessPage />) as ReactNode,
      errorElement: (<PageNotFound />) as ReactNode,
    },
    {
      path: '/',
      element: (
        <ProtectedRoute>
          <AppRouter />
        </ProtectedRoute>
      ),
      errorElement: (<PageNotFound />) as ReactNode,
      children: [
        {
          path: 'scoin',
          element: (
            <ProtectedRoute>
              <Scoin/>
            </ProtectedRoute>
          ) as ReactNode,
        },
        {
          path: 'dashboard',
          element: (
            <ProtectedRoute>
              <AccountExecutiveDashboard />
            </ProtectedRoute>
          ) as ReactNode,
        },
        {
          path: 'client-dashboard',
          element: (
            <ProtectedRoute>
              <ClientDashboard />
            </ProtectedRoute>
          ) as ReactNode,
        },
        {
          path: 'evaluate/:id',
          element: branding?.company_name.toLowerCase().includes('washington')
            ? (<ProtectedRoute><LegacyEnquiryForm /></ProtectedRoute>) as ReactNode
            : (<ProtectedRoute><EnquiryForm /></ProtectedRoute>) as ReactNode,
        },
        {
          path: 'account-list',
          element: (
            <ProtectedRoute>
              <ClientList />
            </ProtectedRoute>
          ) as ReactNode,
        },
        {
          path: `evaluation-assessment-list`,
          element: (
            <ProtectedRoute>
              <EvaluationList />
            </ProtectedRoute>
          ) as ReactNode,
        },
        {
          path: 'account-detail-view/:id',
          element: (
            <ProtectedRoute>
              <AccountDetailView />
            </ProtectedRoute>
          ) as ReactNode,
        },
        {
          path: 'evaluation-assessment-detail/:id',
          element: (
            <ProtectedRoute>
              <EvaluationDetailView />
            </ProtectedRoute>
          ) as ReactNode,
        },
        {
          path: 'evaluation-assessment-detail/:id/evaluation-assessment-analysis-score',
          element: (
            <ProtectedRoute>
              <EvaluationScoreAnalysisContainer />
            </ProtectedRoute>
          ) as ReactNode,
        },
        {
          path: 'create-evaluation-assessment',
          element: (
            <ProtectedRoute>
              <CreateEvaluation />
            </ProtectedRoute>
          ) as ReactNode,
        },
        {
          path: 'edit-evaluation-assessment/:id',
          element: (
            <ProtectedRoute>
              <EditEvaluation />
            </ProtectedRoute>
          ) as ReactNode,
        },
        {
          path: 'create-account',
          element: (
            <ProtectedRoute>
              <CreateClient />
            </ProtectedRoute>
          ) as ReactNode,
        },
        {
          path: 'edit-account/:id',
          element: (
            <ProtectedRoute>
              <EditAccount />
            </ProtectedRoute>
          ) as ReactNode,
        },
        {
          path: 'settings',
          element: (
            <ProtectedRoute>
              <Settings />
            </ProtectedRoute>
          ) as ReactNode,
        },
        {
          path: 'evaluation-assessment-score',
          element: (
            <ProtectedRoute>
              <EvaluationScore />
            </ProtectedRoute>
          ) as ReactNode,
        },
      ],
    },
  ]);

  return (
    <ThemeProvider theme={theme}>
      <React.StrictMode>
        <ToastContainer hideProgressBar theme="colored" autoClose={2000} />
        <Provider store={store}>
          <RouterProvider router={router}></RouterProvider>
        </Provider>
      </React.StrictMode>
    </ThemeProvider>
  );
};

root.render(
  <BrandingProvider>
    <AppInitializer />
  </BrandingProvider>
);
