import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RequestStatus } from '../../../types/request';
import axiosInstance from '../../../config/axios';
import { EndPoints } from '../../../constants/apiUrls';
import { AxiosResponse } from 'axios';
import { EvaluationImageUrlResponse } from '../type';
import { EvaluationImageUrlReducer } from './getEvaluationImageUrl';

export interface EvaluationImageUrlPayload {
  evaluation: string;
  img_url: string;
}

const initialState: EvaluationImageUrlReducer = {
  evaluationImageDetails: null,
  status: RequestStatus.Init,
};

const postEvaluationImageUrlSlice = createSlice({
  name: 'evaluationImageUrl/postEvaluationImageUrl',
  initialState,
  reducers: {
    resetEvaluationImageDetailsState: (state) => {
      state.evaluationImageDetails = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postEvaluationImageUrl.pending, (state) => {
        state.status = RequestStatus.Loading;
      })
      .addCase(postEvaluationImageUrl.fulfilled, (state, action: PayloadAction<EvaluationImageUrlResponse>) => {
        state.status = RequestStatus.Done;
        state.evaluationImageDetails = action.payload;
      })
      .addCase(postEvaluationImageUrl.rejected, (state) => {
        state.status = RequestStatus.Error;
      });
  },
});

export const postEvaluationImageUrl = createAsyncThunk<
  EvaluationImageUrlResponse,
  EvaluationImageUrlPayload
>(
  'postEvaluationImageUrl',
  async (
    evaluationImageDetailsPayload: EvaluationImageUrlPayload,
    { rejectWithValue },
  ) => {
    try {
      const result: AxiosResponse<EvaluationImageUrlResponse> =
        await axiosInstance.post<EvaluationImageUrlResponse>(
          EndPoints.POST_EVALUATION_IMAGE_URL,
          evaluationImageDetailsPayload,
        );
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return result.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const { resetEvaluationImageDetailsState: resetEvaluationCategoriesResultAnalysisState } =
  postEvaluationImageUrlSlice.actions;

export default postEvaluationImageUrlSlice.reducer;