/* eslint-disable indent */
import React, { useState, useRef, useEffect } from 'react';
import RowRadioButtonsGroup from '../RadioButtonsGroup/RadioButtonsGroup';
import { Box, CircularProgress, Paper, Typography, Grid, Container } from '@mui/material';
import LikertScale from '../LikertScale/LikertScale';
import TextEntryInput from '../TextEntryInput/TextEntryInput';
import MultipleChoiceSelect from '../MultipleChoiceSelect/MultipleChoiceSelect';
import { useSelector } from 'react-redux';
import { RootStoreType } from '../../redux/store.type';
import useGetEvaluationQuestions from '../../modules/Accounts/hooks/useGetEvaluationQuestions';
import useGetEvaluationName from '../../modules/Evaluations/hooks/useGetEvaluationName';
import { EvaluationNameResponse } from '../../modules/Evaluations/reducers/getEvaluationName';
import { EvaluationQuestions } from '../../modules/Accounts/types';
import { useNavigate, useParams } from 'react-router-dom';
import { ErrorMessage } from '../../styles/Global.styles';
import usePostEvalutaionSurvey from '../../modules/Evaluations/hooks/usePostEvaluationSurvey';
import { CancelButton, SaveButton } from '../../modules/Accounts/CreateClient/CreateClient.styles';
import translation from '../../locales/en/translation.json';
import { useBranding } from '../../modules/Branding/reducers/getBranding';

const EnquiryForm = () => {
  const [formData, setFormData] = useState<{ [key: string]: any }>({});
  const { id } = useParams<{ id: string }>();
  const { error, loading } = useGetEvaluationQuestions(id || '');
  const { evaluationNameError, evaluationNameLoading } = useGetEvaluationName(id || '');
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const navigate = useNavigate();
  const [areAllQuestionsAnswered, setAreAllQuestionsAnswered] = useState(true);
  const branding = useBranding();

  const questionRefs = useRef<(HTMLDivElement | null)[]>([]);

  const addToRefs = (el: HTMLDivElement | null, index: number) => {
    if (el) {
      questionRefs.current[index] = el;
    }
  };

  const questions: EvaluationQuestions[] = useSelector(
    (state: RootStoreType) => {
      return state.allClientReducer.getEvaluationQuestions.questions;
    },
  );

  const evaluationName: EvaluationNameResponse | null = useSelector(
    (state: RootStoreType) => {
      return state.allEvaluationReducers.getEvaluationNameReducer.evaluationName;
    },
  );

  const evaluationParagraph: string | undefined = useSelector(
    (state: RootStoreType) => {
      if (state?.allEvaluationReducers?.getEvaluationNameReducer?.evaluationName?.evaluation_paragraph !== '') {
        return state?.allEvaluationReducers?.getEvaluationNameReducer?.evaluationName?.evaluation_paragraph;
      }
      return '';
    },
  );

  const marks = [
    {
      value: 1,
      label: '1',
    },
    {
      value: 2,
      label: '2',
    },
    {
      value: 3,
      label: '3',
    },
    {
      value: 4,
      label: '4',
    },
    {
      value: 5,
      label: '5',
    },
  ];

  const renderInput = (question: EvaluationQuestions) => {
    switch (question.question_type_name) {
      case 'Multiple Choice':
        return (
          <RowRadioButtonsGroup
            question={question}
            options={question.options}
            formData={formData}
            setFormData={setFormData}
          />
        );
      case 'Likert Scale':
        return (
          <RowRadioButtonsGroup
            question={question}
            options={[
              {
                option: 'Strongly Disagree',
              },
              {
                option: 'Disagree',
              },
              {
                option: 'Neutral',
              },
              {
                option: 'Agree',
              },
              {
                option: 'Strongly Agree',
              },
            ]}
            formData={formData}
            setFormData={setFormData}
          />
        );
      case 'Number Scale':
        return (
          <LikertScale
            question={question}
            setFormData={setFormData}
            marks={marks}
            formData={formData}
          />
        );
      case 'Text Entry':
        return (
          <TextEntryInput
            question={question}
            setFormData={setFormData}
            formData={formData}
          />
        );
      case 'Multiple Choice Select':
        return (
          <MultipleChoiceSelect
            question={question}
            setFormData={setFormData}
            formData={formData}
            options={question.options}
          />
        );
      default:
        return null;
    }
  };

  const { postSurvey } = usePostEvalutaionSurvey();

  const scrollToFirstUnanswered = () => {
    const firstUnansweredIndex = questions.findIndex(
      question => !Object.prototype.hasOwnProperty.call(formData, question.id)
    );
    if (questionRefs.current[firstUnansweredIndex]) {
      questionRefs.current[firstUnansweredIndex]?.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const onSubmit = () => {
    setHasSubmitted(true);
    const areAllAnswered = questions.every(question =>
      Object.prototype.hasOwnProperty.call(formData, question.id)
    );

    if (areAllAnswered) {
      postSurvey(id!, formData);
    } else {
      scrollToFirstUnanswered();
    }
  };

  useEffect(() => {
    if (hasSubmitted) {
      const allAnswered = questions.every(question =>
        Object.prototype.hasOwnProperty.call(formData, question.id)
      );
      setAreAllQuestionsAnswered(allAnswered);

      if (!allAnswered) {
        scrollToFirstUnanswered();
      }
    }
  }, [formData, questions, hasSubmitted]);

  if (error?.response?.status === 404) {
    return (
      <Typography
        variant="h6"
        sx={{
          margin: '40px auto',
          color: '#9095a1',
          fontFamily: 'Inter',
          fontWeight: 600,
          textAlign: 'center'
        }}
      >
        {"Evaluation doesn't exist"}
      </Typography>
    );
  }

  return (
    <Container maxWidth="lg">
      {loading ? (
        <CircularProgress sx={{ margin: '20vh auto' }} />
      ) : questions.length > 0 || !error ? (
        <>
          <Typography
            variant="h4"
            sx={{
              margin: '20px 0',
              color: '#3e6d14',
              fontFamily: 'Inter',
              fontWeight: 900,
              textAlign: 'center'
            }}
          >
            {evaluationName?.evaluation_name}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              margin: '0 auto 20px',
              color: '#9095a1',
              fontFamily: 'Inter',
              fontWeight: 400,
              textAlign: 'justify',
            }}
          >
            {evaluationParagraph !== '' ? evaluationParagraph : translation.demo_evaluation_survey_para}
          </Typography>

          <form>
            <Grid container spacing={2}>
              {questions.map((question, i) => (
                <Grid item xs={12} key={question.id}>
                  <Paper
                    ref={(el) => addToRefs(el, i)}
                    sx={{
                      padding: '1rem',
                      marginBottom: '1rem',
                      border: '1px solid #E6E6E7',
                      borderRadius: '5px',
                      boxShadow: 'none',
                    }}
                  >
                    <Box>
                      <Box
                        sx={{
                          fontFamily: 'Inter',
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'flex-start',
                          position: 'relative',
                        }}
                      >
                        <span style={{ width: '50px' }}>Q:{` ${i + 1}. `}</span>
                        <Typography variant="body1" sx={{ flex: 10 }}>{question.question}</Typography>
                      </Box>
                      {renderInput(question)}
                      {!Object.keys(formData).includes(question.id) &&
                        hasSubmitted && (
                          <ErrorMessage
                            sx={{ paddingLeft: '25px', fontSize: '16px', color: "red", }}
                          >
                            {translation.this_field_is_required}
                          </ErrorMessage>
                        )}
                    </Box>
                  </Paper>
                </Grid>
              ))}
            </Grid>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                gap: '12px',
                padding: '20px',
              }}
            >
              {hasSubmitted && !areAllQuestionsAnswered && (
                <Typography color="red" sx={{ marginRight: '10px' }}>
                  You need to answer all questions before you can submit.
                </Typography>
              )}
              <SaveButton onClick={onSubmit}>
                Submit
              </SaveButton>
              <CancelButton
                onClick={() => {
                  navigate('/log-in');
                }}
              >
                Cancel
              </CancelButton>
            </Box>
          </form>
        </>
      ) : (
        <Typography
          variant="h4"
          sx={{
            margin: '40px auto',
            color: '#9095a1',
            fontFamily: 'Inter',
            fontWeight: 900,
            textAlign: 'center'
          }}
        >
          Either you have submitted the response once or this evaluation does
          not contain any Question
        </Typography>
      )}
    </Container>
  );
};

export default EnquiryForm;
